<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData">

        <validated-text-area label="About Text*" v-model="model.about_text"
                             :rules="{required: true, max:150}" :disabled="loading"/>

        <validated-text-area label="Text 1*" v-model="model.text_1"
                             :rules="{required: true, max:70}" :disabled="loading"/>

        <validated-text-area label="Text 2*" v-model="model.text_2"
                             :rules="{required: true, max:145}" :disabled="loading"/>

        <file-input class="c-file-input" ref="file" @input="clearFile" label="Banner Image*"
                    name="Banner Image" v-model="model.banner_image" :disabled="loading">
            <template #append>
                <div class="btn-group">
                    <btn v-if="initialData.banner_image"
                         @click="viewFile(initialData.banner_image)"
                         type="button" class="fa fa-eye" size="xs"/>
                </div>
            </template>
        </file-input>

        <validated-checkbox label="Enabled" v-model="model.is_enabled"
                            :rules="{required: false}" :disabled="loading"/>

        <btn class="trn" size="sm" icon="" :loading="loading" color="primary" text="Update"
             loading-text="Updating..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name : 'EditAboutUs',

    props : { initialData : { type : Object } },

    data () {
        return {
            addUrl : urls.cms.aboutUs.addEdit
        };
    },

    methods : {
        clearFile (file) {
            this.initialData.banner_image = '';
        },
        viewFile (file) {
            window.open(file);
        },
        formSuccess () {
            this.$notify('Successfully Edited About Us..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
